var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "x-small": "",
                        fab: "",
                        disabled: _vm.currentPage === 1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.fetchNews(_vm.currentPage - 1)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
                  _c("v-spacer"),
                  _c("h5", [_vm._v(_vm._s(_vm.$t("message.news.news")))]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "x-small": "",
                        fab: "",
                        disabled: !_vm.hasNextPage,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.fetchNews(_vm.currentPage + 1)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  ),
                ],
                1
              ),
              _vm.fieldsLoading
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    _vm._l(9, function (n) {
                      return _c(
                        "v-col",
                        { key: n, attrs: { cols: "12", sm: "6", lg: "3" } },
                        [
                          _c(
                            "v-sheet",
                            { staticClass: "mt-2" },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "mx-auto",
                                attrs: { type: "list-item" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "v-expansion-panels",
                _vm._l(_vm.news, function (item, index) {
                  return _c(
                    "v-expansion-panel",
                    {
                      key: index,
                      staticClass: "mb-2 mt-0",
                      style: { backgroundColor: "rgb(232, 234, 246)" },
                      on: {
                        click: function ($event) {
                          return _vm.markAsRead(item.id)
                        },
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel-header",
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "7" } },
                                    [
                                      _c("h3", { staticClass: "pb-3" }, [
                                        _vm._v(_vm._s(item.title)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-md-right",
                                      attrs: { cols: "12", md: "5" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.published_date)),
                                      ]),
                                      _vm._v(" - "),
                                      _c("span", { staticClass: "mr-md-5" }, [
                                        _vm._v(_vm._s(item.fullname)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12 mt-md-0  mt-3" } },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            item.short_description
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-expansion-panel-content", [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(item.description) },
                        }),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }